import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuardGuard } from './guards/auth-guard.guard';

const routes: Routes = [
  {
    path:'',
    loadChildren: ()=>import('./modules/layout/layout.module').then(m => m.LayoutModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path:'login',
    loadChildren: ()=>import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path:'auth',
    loadChildren: ()=>import('./modules/authenticate/authenticate.module').then(m => m.AuthenticateModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true,preloadingStrategy:PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
